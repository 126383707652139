import React, { useState, useRef, useEffect } from 'react';
import { useWindowSize, useDimension } from '../util.js';
import { PreloadedImageList } from './preloadedImageList.js';

// SLIDER: adapted from 'multi carousel', found online -- multi because any number of them can coexist on the page

// NOTE: MultiCarouselR assumes equal-width items. MultiCarouselFlex does not.

// LATEST & GREATEST version, as of March 2022

import '../../css/imageViewer.scoped.scss';

const MultiCarouselR = (props) => {     // 'R' for React

    const displaySlots = props.displaySlots || 5;   // equivalent to the # of data items that can be displayed before arrow-buttons need to be shown
    const isNoSliding = (props.children.length <= displaySlots);
    const minItemWidth = 60;

    // each item has margin 12px on either side, plus some px for border & highlight
    const spaceAroundItem = 12; // image width will be itemWidthN minus this
    const scrollButtonsWidth = 64;  // space taken up by both the scroller buttons
    const initItemWidth = props.variableWidth && props.maxWidth
        ? Math.max(minItemWidth, Math.floor((props.maxWidth - scrollButtonsWidth) / displaySlots))
        : (props.itemWidth ?? 112);
    const [ itemWidthN, setItemWidthN ] = useState(initItemWidth);
    const alignMode = props.alignMode || 'left';
    const favoriteViewIndex = props.preferIndex || 1;

    // used with center mode:
    const ctrDisplayIdx = Math.floor(displaySlots / 2);

    // use ceil() instead of floor to right-bias the lineup - makes a difference esp. when there are a small, even number of items
    const initEmptySlots = Math.max(0, Math.floor((displaySlots - props.children.length) / 2));

    // special offset for the case of an even no. of items < the no. of display slots -- to center things better
    const [ specialHorizOffset, setSpecialHorizOffset ] = useState((isNoSliding && props.children.length % 2 === 0) ? Math.floor(itemWidthN / 2) : 0);

    // max & minSlot are the constraints upon the display pos. of the first data item
    // slot pos. * itemWidthN == slideX
    const maxSlot = Math.max(0, (displaySlots - props.children.length));
    const minSlot = props.children.length >= displaySlots
        ? (displaySlots - props.children.length)
        : initEmptySlots;

    // to use the center item as selected:
    // const [ selDataIdx, setSelDataIdx ] = useState(ctrDisplayIdx - initEmptySlots);

    const [ selDataIdx, setSelDataIdx ] = useState(props.selectedIdx ?? -1);

    // used only in center mode:
    const [ displaySlotIdx, setDisplaySlotIdx ] = useState(initEmptySlots);

    const wsize = useWindowSize();

    // slideX: can range from 0 down to (mainCarouselWidth - listWidth)
    const [ slideX, setSlideX ] = useState(alignMode === 'left' ? 0 : (initEmptySlots * initItemWidth));

    const adjustItemWidth = (w) => {
        const newW = Math.max(minItemWidth, Math.floor(w / displaySlots));

        setItemWidthN(newW);
        if (alignMode === 'center') {
            setSlideX(displaySlotIdx * newW);   // INVARIANT: slideX == display slot * item width
        }
        if (isNoSliding && props.children.length % 2 === 0) {
            setSpecialHorizOffset(Math.floor(newW / 2));
        }
    }

    const mainCarouselRef = useRef();
    const innerCarouselRef = useRef();

    const [ mainCarouselWidth, mainCarouselHeight ] = useDimension(mainCarouselRef);
    const [ innerCarouselWidth, innerCarouselHeight ] = useDimension(innerCarouselRef);

    useEffect(() => {
        if (mainCarouselRef.current) {
            if (props.variableWidth && props.maxWidth) {
                adjustItemWidth(mainCarouselRef.current.offsetWidth);
            }
        }
    }, [mainCarouselWidth]);

    const moveCarousel = (dir, s) => {

        const newSlideX = (dir === 0) ? slideX - s * itemWidthN : slideX + s * itemWidthN;
        const newDisplaySlotIdx = (dir === 0) ? displaySlotIdx - s : displaySlotIdx + s;

        setSlideX(newSlideX);
        setDisplaySlotIdx(newDisplaySlotIdx);
    }

    const itemSelect = (e, idx) => {
        setSelDataIdx(idx);
    }

    const itemFocus = (e, idx) => {
        // MC tabbing notes:
        // . slideX will either be 0 or negative; slideX / itemWidthN is number of places
        // . try to keep slider stable -- don't move unless necessary
        // . keep 1-2 items before the current and the rest after
        // . in 'left' mode, aim for 2nd position, subject to sliding bounds

        // if (idx === selDataIdx) return;

        setSelDataIdx(idx);
        if (alignMode === 'left') {
            const slideXMin = (Math.floor(mainCarouselWidth / itemWidthN) * itemWidthN - listWidth);
            // what index has 2nd displayed position?
            const place2Index = -slideX / itemWidthN + favoriteViewIndex;
            // if idx < p2I, need to move right, unless slideX is 0 or more
            if (idx < place2Index && slideX < 0) {
                const steps = Math.min(Math.abs(slideX) / itemWidthN, Math.abs(idx - place2Index));
                // actually, if fave index is 1 and we're at 0, so we'd be moving 1 step to the right, override & do nothing
                // - it just doesn't feel right
                if (!(favoriteViewIndex === 1 && steps === 1)) {
                    moveCarousel(1, steps);
                }
            } else {
                // if idx > p2I, need to move left, unless slideX is slideXMin or less

                if (idx > place2Index && slideX > slideXMin) {
                    const steps = Math.min((slideX - slideXMin) / itemWidthN, Math.abs(idx - place2Index));
                    moveCarousel(0, steps);
                }
            }
        } else if (alignMode === 'center') {
            const newDisplaySlotIdx = Math.max(minSlot, Math.min(maxSlot, (ctrDisplayIdx - idx + initEmptySlots)));
            const newSlideX = newDisplaySlotIdx * itemWidthN;

            // only move if necessary
            if (props.children.length > displaySlots) {
                setSlideX(newSlideX);
                setDisplaySlotIdx(newDisplaySlotIdx);
            }
        }
    }

    const moreToTheLeft = () => {
        return (slideX < 0);
    }

    const moreToTheRight = () => {
        return (mainCarouselWidth !== 0 && slideX + innerCarouselWidth > mainCarouselWidth);
    }

    // add the focus function to each child, that will be called when it receives the focus
    const elements = React.Children.toArray(props.children).map((hit, idx) => {
        return React.cloneElement(hit, {
            focusfunc: (e) => itemFocus(e, idx),
            setSelFunc: (e) => itemSelect(e, idx),
            key: idx,
            idx: idx,
            selDataIdx: selDataIdx,
            imgWidth: (props.variableWidth && props.maxWidth) ? (itemWidthN - spaceAroundItem) : props.imgWidth,
            itemWidth: itemWidthN
        });
    });

    const listWidth = itemWidthN * elements.length;

    return (
        <div className={`MultiCarousel ${alignMode === 'left' ? 'alignleft' : (alignMode === 'center' ? 'ml-auto mr-auto d-flex alignctr' : '')}`}
            style={(props.maxWidth && props.maxWidth < wsize.width ? {maxWidth: `${props.maxWidth}px`} : {maxWidth: `${wsize.width - 64}px`} )}
        >
            <button
                type="button"
                title="Previous"
                className={`btn p-0 btn-link sliding-button sliding-button-left ${props.buttonClass ? props.buttonClass : 'sliding-button-midlevel'} ${moreToTheLeft() || props.alwaysShowButtons ? '' : 'hid'}`}
                onClick={(e) => moveCarousel(1, 1)}
            >
                <div className="icon icon-arrow-left-lg icon--white icon-lg">
                    <span className="sr-only">Previous</span>
                </div>
            </button>
            <div className="MultiCarouselView" ref={mainCarouselRef}>
                <div ref={innerCarouselRef} className={`MultiCarouselInner ${alignMode === 'center' ? 'd-flex align-items-center' : ''}`} style={{width: `${listWidth}px`, transform: `translateX(${slideX + specialHorizOffset}px)`}}>
                {elements}
                </div>
            </div>
            <button
                type="button"
                title="Next"
                className={`btn p-0 btn-link sliding-button sliding-button-right ${props.buttonClass ? props.buttonClass : 'sliding-button-midlevel'} ${moreToTheRight() || props.alwaysShowButtons ? '' : 'hid'}`}
                onClick={(e) => moveCarousel(0, 1)}
            >
                <div className="icon icon-arrow-right-lg icon--white icon-lg">
                    <span className="sr-only">Next</span>
                </div>
            </button>
        </div>
    )
}

// ========================================================================================================

// new version of MC doesn't require items to have identical widths

const MultiCarouselFlex = (props) => {

    const eltIdPrefix = props.eltIdPrefix;

    const mode = props.mode ?? 'normalMode';
    // modes other than normal:
    // slideshowMode == images are expected to be big; show one at a time, centered

    const gapDefault = 24;

    const [ gapBetweenItems, setGapBetweenItems ] = useState(gapDefault); // make sure this matches the 'gap' spec for the class MultiCarouselInner

    // selDataIdx is the index of the selected item, within its group
    const [ selDataIdx, setSelDataIdx ] = useState(props.selectedIdx ?? -1);

    // start with the 0th item showing in slot 0 -- this will change as the slider moves left or right
    const [ inSlotZeroIdx, setInSlotZeroIdx ] = useState(props.selectedIdx ?? 0);

    const [ singletonMode, setSingletonMode ] = useState(props.preload ?? false);   // if preloading, we will be in singleton mode at first

    // this will sync w. singletonMode but with a delay
    const [ singletonModeDelayed, setSingletonModeDelayed ] = useState(singletonMode);

    const getSlideXFromItemIdx = (itemIdx) => {
        if (singletonMode) {
            return 0;
        }
        // console.log('getting slidex for idx='+itemIdx);
        // need to know widths of all items (plus gaps) before the specified index
        let sum = 0;
        for (let i=0; i<itemIdx; i++) {
            const el = document.getElementById(eltIdPrefix + '-' + i);
            const itemWidth = el ? el.offsetWidth : 0;
            // console.log('elt. '+i+' offsetWidth='+itemWidth);
            sum += itemWidth + gapBetweenItems;
        }
        //console.log(-sum);
        return sum > 0 ? -sum : sum;
    }

    // slideX: can range from 0 down to (mcvw - listWidth [i.e. mciw])
    const [ slideX, setSlideX ] = useState(getSlideXFromItemIdx(inSlotZeroIdx));

    const [ slideTransitionEnabled, setSlideTransitionEnabled ] = useState(props.initialSlideTransition ?? true);

    const [ children, setChildren ] = useState(props.children);
    const [ imgUrls, setImgUrls ] = useState(props.preload
        ? React.Children.toArray(children).map((hit) => {
                return hit.props.imageUrl;
            })
        : []);

    const [ childrenWidth, setChildrenWidth ] = useState(0);

    // add the focus function to each child, that will be called when it receives the focus
    // note: children need to be components, not just html elements like div
    const clonedItems = React.Children.toArray(props.children).map((hit, idx) => {
        // console.log('child: ');
        // console.log(hit);
        const el = React.cloneElement(hit, {
            ...hit.props,
            focusfunc: (e) => itemFocus(e, idx),
            setSelFunc: (e) => itemSelect(e, idx),
            idx: idx,
            selDataIdx: selDataIdx,
            myWidth: childrenWidth,
            mode: mode
        });
        return el;
    });

    const mcvRef = useRef();
    const [ mcvw, mcvh ] = useDimension(mcvRef);

    // clone our children as soon as mcvw is set > 0
    useEffect(() => {
        console.log('mcvw update, now = '+mcvw);
        setChildrenWidth(mcvw);
    }, [mcvw]);

    useEffect(() => {
        // console.log('useEffect: children changed. Null ? '+(children && children.length > 0 ? 'NO' : 'YES'));
        // console.log(children);
        if (mcvw > 0 && children && children.length > 0) {
            setSingletonMode(props.preload);
            console.log('set singleton mode to '+props.preload);
            setImgUrls(props.preload
                ? React.Children.toArray(children).map((hit) => {
                        return hit.props.imageUrl;
                    })
                : []);
        }
    }, [children]);

    useEffect(() => {
        if (props.children !== children) {
            setChildren(props.children);
        }
    }, [props.children]);

    // once images are preloaded, turn off singleton mode
    useEffect(() => {
        if (typeof singletonMode !== 'undefined') {
            // console.log('useEffect: singletonMode changing to '+singletonMode);
            if (children && children.length > 1) {
                // if there are no children, it probably means we're unmounting, and there's no need to mess with singletonModeDelay
                // if we tried this setTimeout with no children, it could cause the React error:
                // "Can't perform a React state update on an unmounted component"
                setTimeout(() => {
                    // console.log('setting singletonModeDelayed to '+singletonMode);
                    setSingletonModeDelayed(singletonMode);
                    // console.log('set singletonModeDelayed to '+singletonMode);
                }, 1050);   // this should be >= the transition-duration for .MultiCarouselInner
            }
        }
    }, [singletonMode]);

    // when image preloading finishes, change from singleton mode, if there are more than 1 children
    const onImgPreloadFinish = () => {
        console.log('done preloading');
        if (singletonMode && children && children.length > 1) {
            console.log('onImgPreloadFinish: setting singleton mode to false');
            setSingletonMode(false);
        }
    }

    const mciRef = useRef();    // the 'inner' div that contains a variable number of items
    const [ mciw, mcih ] = useDimension(mciRef);
    useEffect(() => {
        // console.log('mciw EFFECT = '+mciw);
        // console.log(mciRef.current);
        if (mciRef.current) {
            // console.log('new mciw / offsetWidth = ' + mciRef.current.offsetWidth + '; current: ');
            // console.log(mciRef.current);
            // console.log('getting slide x from idx '+inSlotZeroIdx);
            setSlideX(getSlideXFromItemIdx(inSlotZeroIdx));
            if (mode === 'slideshowMode') {
                // gap will be up to half the mcvw
                const newGap = Math.max(gapDefault, Math.floor(mcvw * 0.5));
                // console.log('setting gap between items to '+newGap+'; mcvw='+mcvw+'; mcvwFrac='+mcvwFrac);
                setGapBetweenItems(newGap);
            }
        }
    }, [mciw]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            console.log('multiCarousel mounted; in slot zero idx: '+inSlotZeroIdx+'; enabling slide transition');
            console.log('selDataIdx: '+selDataIdx);
            setTimeout(() => {
                setSlideTransitionEnabled(true);
            }, 100);
        }
        return () => mounted = false;
    }, []);

    const moveCarousel = (dir, s) => {  // s = number of steps to move -- BUT might only work when 1
        // console.log('moveCarousel:' + dir);
        // if sliding to the left, the element in slot 0 determines the distance. if sliding to the right, the element in slot -1 (i.e. just hidden to left of slot 0) determines it.
        const wElementIdx = (dir === 0) ? inSlotZeroIdx : inSlotZeroIdx - 1;
        if (wElementIdx < 0 || wElementIdx >= (singletonMode ? 1 : clonedItems.length)) {
            console.log('Error: out of range idx');
            console.log(clonedItems);
            return;
        }
        const el = document.getElementById(eltIdPrefix + '-' + wElementIdx);
        // console.log('width of idx '+wElementIdx);
        const itemWidth = el ? el.offsetWidth : 0;
        const delta = itemWidth + gapBetweenItems;
        // console.log('width of idx '+wElementIdx+' = '+itemWidth+'; id '+eltIdPrefix + '-' + wElementIdx+'; moving by '+delta+'px');
        const newSlideX = (dir === 0) ? slideX - s * delta : slideX + s * delta;
        setSlideX(newSlideX);
        const newInSlotZeroIdx = (dir === 0) ? inSlotZeroIdx + 1 : inSlotZeroIdx - 1;
        setInSlotZeroIdx(newInSlotZeroIdx);
        // console.log('new in slot 0: idx '+newInSlotZeroIdx+' newSlideX: '+newSlideX);
    }

    const itemSelect = (e, idx) => {
        // console.log('itemselect (01): '+idx);
        setSelDataIdx(idx);
    }

    const itemFocus = (e, idx) => {
        // MC tabbing notes:
        // . slideX will either be 0 or negative; slideX / itemWidthN is number of places
        // . try to keep slider stable -- don't move unless necessary
        // . keep 1-2 items before the current and the rest after
        // . in 'left' mode, aim for 2nd position, subject to sliding bounds

        // if (idx === selDataIdx) return;

        // console.log('itemFocus: setting sel idx to ' + idx);

        // setSelDataIdx(idx);
        // const slideXMin = (Math.floor(mcvw / itemWidthN) * itemWidthN - listWidth);
        // // what index has 2nd displayed position?
        // const place2Index = -slideX / itemWidthN + favoriteViewIndex;
        // // if idx < p2I, need to move right, unless slideX is 0 or more
        // if (idx < place2Index && slideX < 0) {
        //     const steps = Math.min(Math.abs(slideX) / itemWidthN, Math.abs(idx - place2Index));
        //     // actually, if fave index is 1 and we're at 0, so we'd be moving 1 step to the right, override & do nothing
        //     // - it just doesn't feel right
        //     if (!(favoriteViewIndex === 1 && steps === 1)) {
        //         moveCarousel(1, steps);
        //     }
        // } else {
        //     // if idx > p2I, need to move left, unless slideX is slideXMin or less
        //     // console.log('itemFocus: place2idx == ' + place2Index + '; slidex: ' + slideX + '; slidexmin: ' + slideXMin);
        //     if (idx > place2Index && slideX > slideXMin) {
        //         const steps = Math.min((slideX - slideXMin) / itemWidthN, Math.abs(idx - place2Index));
        //         moveCarousel(0, steps);
        //     }
        // }
    }

    const slideLeftButtonVisible = () => {
        if (singletonModeDelayed) {
            return false;
        }
        return (slideX < 0);
    }

    const slideRightButtonVisible = () => {
        if (singletonModeDelayed) {
            return false;
        }
        return (mcvw !== 0 && mciw !== 0 && slideX + mciw > mcvw);
    }

    return (
        <div className={`MultiCarousel alignleft`}>
            <button
                type="button"
                title="Previous"
                className={`btn btn-link sliding-button sliding-button-left ${props.buttonClass ? props.buttonClass : 'sliding-button-midlevel'} ${slideLeftButtonVisible() || props.alwaysShowButtons ? '' : 'hid'}`}
                onClick={(e) => moveCarousel(1, 1)}
            >
                <div className={`icon icon-arrow-left-lg ${(props.buttonColorClass || 'icon--black')} icon-lg`}>
                    <span className="sr-only">Previous</span>
                </div>
            </button>
            <div className="MultiCarouselView"
                ref={mcvRef}
            >
                <div className={`MultiCarouselInner ${(!slideTransitionEnabled || singletonModeDelayed) ? 'notransition' : ''}`}
                    style={{transform: `translateX(${slideX}px)`, gap: `${gapBetweenItems}px`}}
                    ref={mciRef}
                >
                    {clonedItems.filter((d, i) => { return !singletonMode || i === selDataIdx })}
                </div>
                {
                props.preload &&
                    <PreloadedImageList
                        imgUrls={imgUrls}
                        whenDone={onImgPreloadFinish}
                    />
                }
            </div>
            <button
                type="button"
                title="Next"
                className={`btn btn-link sliding-button sliding-button-right ${props.buttonClass ? props.buttonClass : 'sliding-button-midlevel'} ${slideRightButtonVisible() || props.alwaysShowButtons ? '' : 'hid'}`}
                onClick={(e) => moveCarousel(0, 1)}
            >
                <div className={`icon icon-arrow-right-lg ${(props.buttonColorClass || 'icon--black')} icon-lg`}>
                    <span className="sr-only">Next</span>
                </div>
            </button>
        </div>
    )
}

export { MultiCarouselR, MultiCarouselFlex };