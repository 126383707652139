import React from 'react';

const ItemOverlay = (props) => {

    return (
        <div className="section-archive-series-details-card-overlay">
            <div className="grid-object-overlay-container">
                <div className="grid-object-overlay">
                    <div className="grid-object-overlay-1">
                        <div>
                            <div className={`grid-object-overlay-qv ${ props.hideFullRecordLink ? 'no-record-link' : ''}`} onClick={props.clickFunction}>
                                <div className="no-ptr-events">
                                    <span className="icon icon-exp-window icon--white icon-lg"></span>
                                </div>
                                <div className="no-ptr-events">Quick View</div>
                            </div>
                            <div className={`pt-3 ${ props.hideFullRecordLink ? 'd-none' : ''}`}>
                                <div>
                                    <span className="icon icon-research icon--white icon-lg"></span>
                                </div>
                                <div>View Full Record</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ItemOverlay };