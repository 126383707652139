/*
facetControl.js
*/

import React, {useState, useRef, useEffect, useContext} from 'react';
import { FixedSizeList as List } from 'react-window';

import { SearchConfigContext } from '../context/searchConfigContext';

import '../../css/search.scoped.scss';

export function DateFilterFirstLastInput(props) {

  // CHECKING YEAR INPUTS:
  const isValidYear = (val) => {
    const isnum = /^\d+$/.test(val);
    return (isnum && val.length === 4);
  }

  const yearInputKeyUp = (e, idx) => {
    const val = e.currentTarget.value;
    const entryOK = (val.length === 0 || isValidYear(val));
    let newInvalids = [...yearsInvalid];
    newInvalids[idx] = !entryOK;
    setYearsInvalid(newInvalids);
    let newEmptys = [...yearsEmpty];
    newEmptys[idx] = (val.length === 0);
    setYearsEmpty(newEmptys);
    // apply button is disabled iff both year fields are empty OR either year field has invalid data
    setApplyYearDisabled(newInvalids[0] || newInvalids[1] || (newEmptys[0] && newEmptys[1]));

    // giving the 'benefit of the doubt', clear any error message for this input
    let newErr = [...yearsErrorMsg];
    newErr[idx] = false;
    setYearsErrorMsg(newErr);

    // update the year range values
    let newYr = [...yearRange];
    newYr[idx] = (val.length === 0 ? null : val);
    setYearRange(newYr);
  }

  const yearInputBlur = (e, idx) => {
    const val = e.currentTarget.value;
    const entryOK = (val.length === 0 || isValidYear(val));
    let newErr = [...yearsErrorMsg];
    newErr[idx] = (!entryOK);
    setYearsErrorMsg(newErr);
    // refocus?
  }

  const yearInputFocus = (e, idx) => {}

  const [ applyYearDisabled, setApplyYearDisabled ] = useState(true);
  const [ yearsInvalid, setYearsInvalid ] = useState([false, false]);
  const [ yearsEmpty, setYearsEmpty ] = useState([true, true]);
  const [ yearsErrorMsg, setYearsErrorMsg ] = useState([false, false]);
  const [ yearRange, setYearRange ] = useState([null, null]);

  return <div className={props.className}>
          <form className="year-select" onSubmit={(e) => {
              e.preventDefault();
              props.applyFilters({ date_range: yearRange }, []);
              if (!props.mobileMode && props.collapseFunc) {
                props.collapseFunc();
              }
            }}>
            <div className="filters-facet-headline">
              <h5>{`${props.labels.prompt}:`}</h5>
            </div>
            <div className="w-50">
              <div>
                <label htmlFor="year-select-from">{props.labels.first}</label>
                <input type="tel" placeholder={props.labels.placeholder} pattern="[0-9]{4}" minLength="4" maxLength="4" max="9999" id="year-select-from" className="input mb-2" onFocus={(e) => yearInputFocus(e, 0)} onBlur={(e) => yearInputBlur(e, 0)} onKeyUp={(e) => yearInputKeyUp(e, 0)} />
                <div className={`filters-error-msg ${(yearsErrorMsg[0]) ? '' : 'd-none'}`} id="year-select-from-error">
                  {props.labels.error}
                </div>
              </div>
              <div>
                <label htmlFor="year-select-to">{props.labels.last}</label>
                <input type="tel" placeholder={props.labels.placeholder} pattern="[0-9]{4}" minLength="4" maxLength="4" max="9999" id="year-select-to" className="input mb-2" onFocus={(e) => yearInputFocus(e, 1)} onBlur={(e) => yearInputBlur(e, 1)} onKeyUp={(e) => yearInputKeyUp(e, 1)} />
                <div className={`filters-error-msg ${(yearsErrorMsg[1]) ? '' : 'd-none'}`} id="year-select-to-error">
                  {props.labels.error}
                </div>
              </div>
              <button className="button" type="submit" disabled={applyYearDisabled} id="applyYearRange">{props.labels.apply}</button>
            </div>
          </form>
        </div>
}

export function FilterControl({aggregations, filters, applyFilters, clearFilter, collapseFunc, count, filterConfig, mobileMode}) {

  // Wraps the actual Filter behaviors / instantiations..

  /* FIXME: This should always return the outer div and only swap out the inner component so React can better diff it*/

  let aggregation;
  switch (filterConfig.type) {
    case 'large-term':
      aggregation = (filterConfig.agg_name in aggregations ) ? aggregations[filterConfig.agg_name] : { buckets: [] }
      return <div className={`landing-filters-cell ${mobileMode ? '' : 'landing-filters-bt'}`}>
              <FacetItemList className=""
                sortByCount={ false }
                sorter={ filterConfig.sorter ?? undefined }
                name={filterConfig.label}
                allFacetsLabel={filterConfig.allFacetsLabel ?? "Any"}
                filterName={filterConfig.agg_name}
                filters={filters}
                applyFilters={applyFilters}
                clearFilter={clearFilter}
                aggregation={ filterConfig.reducer ? aggregation.buckets.reduce(filterConfig.reducer, []) : aggregation.buckets.map( filterConfig.normalizer ) }
                count={count}
                collapseFunc={collapseFunc}
                large={true}
              />
            </div>
    case 'term':
      aggregation = (filterConfig.agg_name in aggregations ) ? aggregations[filterConfig.agg_name] : { buckets: [] }
      return <div className={`landing-filters-cell ${mobileMode ? '' : 'landing-filters-bt'}`}>
              <FacetItemList className=""
                sortByCount={ false }
                sorter={ filterConfig.sorter ?? undefined }
                name={filterConfig.label}
                allFacetsLabel={filterConfig.allFacetsLabel ?? "Any"}
                filterName={filterConfig.agg_name}
                filters={filters}
                applyFilters={applyFilters}
                clearFilter={clearFilter}
                aggregation={ filterConfig.reducer ? aggregation.buckets.reduce(filterConfig.reducer, []) : aggregation.buckets.map( filterConfig.normalizer ) }
                count={count}
                collapseFunc={collapseFunc}
              />
            </div>
    case 'hier-term':
      let filterAgg = aggregations['type_path']

      aggregation = (filterConfig.agg_name in aggregations ) ? aggregations[filterConfig.agg_name] : { buckets: [] }
      var broadAggregation = (filterConfig.broad_agg_name in aggregations ) ? aggregations[filterConfig.broad_agg_name] : { buckets: [] }

      // Since ES does not manage nested terms we use the type path filter to do this
      // Filter both aggregations so that they only show things on the filtering type path
      const inHigherAgg = (buck) => {
        // Returns true if the broad agg is set and if this key is in one of its child aggs
        if (filters[filterConfig.broad_agg_name]) {
          return filterAgg.buckets.some( filterAgg =>  filterAgg.key.includes(`//${buck.key}`) )
        } else {
          return true
        }
      }

      const inLowerAgg = (buck) => {
        // Retrusn true if the lower agg is set and if this key is one of its parent aggs
        if (filters[filterConfig.agg_name]) {
          return filterAgg.buckets.some( filterAgg => filterAgg.key.includes(`${buck.key}//`) )
        } else {
          return true
        }
      }

      return <React.Fragment>
              <div className={`landing-filters-cell ${mobileMode ? '' : 'landing-filters-bt'}`}>
                <FacetItemList className=""
                  sortByCount={ false }
                  sorter={ filterConfig.sorter ?? undefined }
                  name={filterConfig.broadLabel}
                  allFacetsLabel={filterConfig.allFacetsLabel ?? "Any"}
                  filterName={filterConfig.broad_agg_name}
                  filters={filters}
                  applyFilters={applyFilters}
                  clearFilter={clearFilter}
                  aggregation={ filterConfig.reducer ? broadAggregation.buckets.filter(inLowerAgg).reduce(filterConfig.reducer, []) : broadAggregation.buckets.filter(inLowerAgg).map( filterConfig.normalizer ) }
                  count={count}
                  // collapseFunc={ () => return}
                />
              </div>
              <div className={`landing-filters-cell ${mobileMode ? '' : 'landing-filters-bt'}`}>
                <FacetItemList className=""
                  sortByCount={ false }
                  sorter={ filterConfig.sorter ?? undefined }
                  name={filterConfig.label}
                  allFacetsLabel={filterConfig.allFacetsLabel ?? "Any"}
                  filterName={filterConfig.agg_name}
                  filters={filters}
                  applyFilters={applyFilters}
                  clearFilter={clearFilter}
                  aggregation={ filterConfig.reducer ? aggregation.buckets.filter(inHigherAgg).reduce(filterConfig.reducer, []) : aggregation.buckets.filter(inHigherAgg).map( filterConfig.normalizer ) }
                  count={count}
                  collapseFunc={collapseFunc}
                />
              </div>
            </React.Fragment>
    case 'date-first-last':
      return <div className={`landing-filters-cell ${mobileMode ? '' : 'landing-filters-bt'}`}>
                <DateFilterFirstLastInput className=""
                  filters={filters}
                  applyFilters={applyFilters}
                  collapseFunc={collapseFunc}
                  mobileMode={mobileMode}
                  labels={filterConfig.labels}
                />
              </div>
    default:
      break;
  }

    // FIXME: Take a config, switch on its name or type
    // case FacetItemList
    // case DateFilterFirstLastInput
    // case Typeahead filter enable thingy

}

export function SearchableIndexedFacetItemList(props) {

  // return <FacetItemList ...props />
}

export function FacetItemList(props) {
    /*
        Produces a clickable list of items in props.aggregation
    */

    const config = useContext(SearchConfigContext)
    const mapping = config.mapping;

    function clearFilter(e) {
      e.preventDefault();
      props.clearFilter(props.filterName)
    }

    function clickHandler(e, bucket, disableIfEmpty=true) {
      e.preventDefault();

      if ( disableIfEmpty && bucket.active.doc_count === 0 ) {
        return // Do nothing
      }

      let result = {};
      switch (props.filterName) {
        case 'date_range':
          // console.log(bucket);
          result[props.filterName] = dateRangeInputFormatter(bucket);
          break;
        default:
          result[props.filterName] = bucket.key.label;
          break;
      }
      props.applyFilters(result, props.filterUnsets || []);

      // close the filter pane upon selection:
      if (!props.mobileMode && props.collapseFunc) {
        props.collapseFunc();
      }

      listRef.current?.scrollToItem(0)
    }

    const handleIndexClick = (idxLetter) => {

      let listRowIndex = indexMarkers[idxLetter];

      if ( listRowIndex ) {
        listRef.current.scrollToItem(listRowIndex - 1,'start') // -1 to account for top-row currently selected
      }

    }

    const doesBucketMatchCurrentFilter = (bucket) => {
      let result;
      switch (props.filterName) {
        case 'date_range':
          if (!props.filters || !props.filters[props.filterName] || props.filters[props.filterName].length !== 2 || !bucket.key) {
            result = false;
          } else {
            // undefined must match undefined
            const fromMatch = (!props.filters[props.filterName][0])
              ? (!bucket.key.from_as_string)
              : (props.filters[props.filterName][0].toString() === bucket.key.from_as_string);
            const toMatch = (!props.filters[props.filterName][1])
              ? (!bucket.key.to_as_string)
              : (props.filters[props.filterName][1].toString() === bucket.key.to_as_string);
            // console.log('from as string = '+bucket.key.from_as_string+'; to as string = '+bucket.key.to_as_string);
            // console.log('fromMatch = '+fromMatch+'; toMatch = '+toMatch);
            result = fromMatch && toMatch;
          }
          break;
        case 'entity_type':
        case 'classification':
        default:
          if (!props.filters || !props.filters[props.filterName] || !bucket.key) {
            result = false;
          } else {
            result = (props.filters[props.filterName] === bucket.key.label);
          }
          break;
      }
      return result;
    }

    let collator = new Intl.Collator('fr')

    if (props.sorter) {
      props.aggregation.sort( props.sorter );
    } else if (props.sortByCount) {
      props.aggregation.sort((bucket1,bucket2) => { return bucket2.active.doc_count - bucket1.active.doc_count })
    } else {
      props.aggregation.sort( (b1, b2) => {
        if ( b1.active.doc_count === 0 && b2.active.doc_count == 0) {
          return 0
        } else if ( b1.active.doc_count === 0 ) {
          return 1
        } else if ( b2.active.doc_count === 0 ) {
          return -1
        } else {
          return collator.compare(b1.key.label,b2.key.label)
        }

      } )
    }

    let sortedAgg = props.aggregation ?? []

    let facetIndexes = Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ");
    let steps = 2;

    var facetIndexRanges = []
    for ( let index of Array.from(Array(Math.ceil(facetIndexes.length / steps)).keys()) ) {
      let startSliceIdx = index * steps;
      var endSliceIdx = (index + 1) * steps

      if (endSliceIdx >= facetIndexes.length) {
        endSliceIdx = facetIndexes.length - 1
      }

      facetIndexRanges.push([ facetIndexes[startSliceIdx], facetIndexes[endSliceIdx] ])
    }

    const listRef = useRef()
    const outerRef= useRef()


    const firstChar = (text) => {
      // Normalize unicode to char-plus-diacritics, remove diacritics, extract chars, get the first
      let normalized = text.normalize("NFD");
      let noDiacritics = normalized.replace(/\p{Diacritic}/gu,"")
      let chars = noDiacritics.match(/(\w)/g)

      if ( chars.length > 0 ) {
        return chars[0].toUpperCase();
      }
    }

    // A map of the first index with this letter
    let indexMarkers = props.aggregation?.reduce( (accumulated,current,idx) => {
      let char = firstChar(current.key.label);

      if ( Object.keys(accumulated).includes(char) || current.active.doc_count === 0 ) {
        return accumulated
      } else {
        return { ...accumulated, [char]: idx }
      }

    },{})

    const Row = ({index, style}) => {
        if (index === 0) {
          return ( <li style={style} className={`${!props.filters || !props.filters[props.filterName] ? 'active' : ''}`} key="key-any">
                      <a className={`dropdown-item ${!props.filters || !props.filters[props.filterName] ? 'active' : ''}`}
                        href="#"
                        onClick={(e) => {e.preventDefault(); clearFilter(e)}}
                      >{props.allFacetsLabel}&nbsp;<span className="count">({props.count})</span></a>
                    </li> )
        } else {

          var label = sortedAgg[index - 1].key.label;
          switch (props.filterName) {
            case 'meta_type':
              // FIXME: Remove this object boilerplate by exposing a simpler func sign'tr
              label = mapping.displayType({ _source: { type: label }})
            default:
              break;
          }

          // FIXME: Get the proper params for this href from the config / mapping
          return ( <li style={style} className={`${doesBucketMatchCurrentFilter(props.aggregation[index - 1]) ? 'active' : ''}`} key={`key-${index-1}`}>
                      <a className={`dropdown-item ${ ( props.aggregation[index - 1].active?.doc_count === 0 ) ? 'disabled' : '' } ${doesBucketMatchCurrentFilter(props.aggregation[index - 1 ]) ? 'active' : ''}`}
                        href="#"
                        onClick={(e) => {e.preventDefault(); clickHandler(e, props.aggregation[index - 1])}}
                      >{ label } <span className="count">({ sortedAgg[index - 1].active?.doc_count })</span></a>
                    </li> ) }

        }

    useEffect( () => {
      if (listRef.current && outerRef.current) {
        for ( let attr of outerRef.current.getAttributeNames() ) {
          if (attr.startsWith('data-v-')) {
            // console.log(listRef)
            listRef.current._outerRef.setAttribute(attr,'')
          }
        }
      }
    },[listRef,outerRef])

    return <div className={props.className}>
      <div ref={outerRef} className="filters-facet-headline">
        <h5>{props.heading || props.name}</h5>
      </div>
      <div className="landing-filters-dropdown is-flex-direction-row">
        {
          props.large ? <ul style={{flex: 'column', width: '15%'}}>{ facetIndexRanges.map(range => { return <li><a href="javascript:;" onClick={ () => handleIndexClick(range[0])}>{`${range[0]}-${range[1]}`}</a></li>})}</ul> : ''
        }
        <List className="landing-filters-dropdown-menu"
              ref={listRef}
              itemCount={  ( props.aggregation.length ?? 0 ) + 1}
              height={290}
              itemSize={40}
              width={330}
              outerElementType='ul'
        >
          { Row }
        </List>
      </div>
    </div>
}