import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';

import { SearchConfigContext } from '../context/searchConfigContext.js';

import { FilterControl } from './facetControl.js';
import { FiltersAccordion } from './filtersAccordion.js';
import { truncate, useWindowSize } from '../util.js';

import '../../css/search.scoped.scss';

const FilterBar = (props) => {
  const config = useContext(SearchConfigContext);
  const mapping = config.mapping

  const labels = config.searchConfig.labels.reduce((acc,cur) => { acc[cur.name] = cur.label ; return acc }, {} )

  const filterConfigs = config.searchConfig.filters;
  const filterPaneConfigs = config.searchConfig.filterPanes.map( (paneConfig) => {

    if (paneConfig.type === "pane") {
      let filters = paneConfig.filters.flatMap( filt => filterConfigs.filter( filterConfig => filterConfig.name === filt ) )
      return { ...paneConfig, filters }
    } else {
      return { ...paneConfig }
    }

  });

  // console.log('filterPaneConfigs');
  // console.log(filterPaneConfigs);

  const [ openPane, setOpenPane ] = useState(null);
  // changes BEFORE openPane:
  const [ openPanePre, setOpenPanePre ] = useState(null);
  // changes AFTER openPane:
  const [ openPanePost, setOpenPanePost ] = useState(null);

  const [ yearRange, setYearRange ] = useState([null, null]);
  const [ currentFilters, setCurrentFilters ] = useState(props.currentFilters);

  // for the mobile filter panel:
  const [ fpOpen, setFpOpen ] = useState(false);
  const [ fpLeft, setFpLeft ] = useState(0);

  const openMFP = (e) => {
    // console.log('openMFP:');
    setFpOpen(true);
    setTimeout(() => {  // delay so the transition applies, because after display/open
      setFpLeft('0');
    }, 15);
  }

  const hideMFP = () => {
    setFpLeft('-100%');
    setTimeout(() => {
      setFpOpen(false);
    }, 250);
  }

  const getFilterPillLabel = (key, d) => {
    /* Returns data formatted for a filter pill according to the type of filter it is */
    switch (key) {
      case 'images':
        return filterConfigs.filter( filt => filt.name === "images" ).reduce( (acc,cur) => cur.labels.pill, '')
      case 'dateRange':
      case 'date_range':
        if (!d[0]) {
          return 'Before ' + (d[1]);
        } else {
          return (d[0]) + ' - ' + (d[1] || 'Present');
        }
      case 'meta_type':
        // FIXME: Remove this boilerplate and expose a pure switch function
        return mapping.displayType({ _source: { type: d }})
      default:
        return d;
    }
  }

  const refs = new Array(filterPaneConfigs.length);
  filterPaneConfigs.forEach(paneConfig => {
    refs[paneConfig.name] = useRef();
  });

  const collapseRef = (id) => {
    // console.log('collapseRef: id = '+id+'; openpane = '+openPane);
    if (openPane === id) {
      setOpenPane(null);
    }
  }

  const collapseAll = () => {
    // console.log('collapseAll; setting openPane to null');
    setOpenPane(null);
  }

  useEffect(() => {
    // console.log('effect: setting openPane to openPanePre = '+openPanePre);
    setOpenPane(openPanePre);
  }, [openPanePre]);

  /* FIXME: A useEffect handler that fires when openPane happens and does the animation ease-in (ie, `collapsing` vs `collapsed`) */
  useEffect(() => {
    // console.log('openPane chgd; new openPane: '+openPane);
    const buttons = document.querySelectorAll('.filters-pane-button');
    buttons.forEach(b => {
      if (b.id === 'collapseFiltersButton-'+openPane) {
        b.classList.remove('collapsed');
      } else {
        b.classList.add('collapsed');
      }
    });
  }, [openPane]);

  // keep track of window size:
  const wsize = useWindowSize();
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const isMobileScreenWidth = (w) => (w < 768);
  let showFilterPillBar;
  switch (true) {
  case (props.currentFilters && Object.keys(props.currentFilters).length === 1 && Object.keys(props.currentFilters).includes("exhibition")):
    showFilterPillBar = false
    break
  default:
    showFilterPillBar = props.currentFilters && Object.keys(props.currentFilters).length > 0
    break
  } 
  
  const clearFiltersAndClosePanel = () => {
    props.clearFilters();
    hideMFP();
  }

  useEffect(() => {

    const ismobile = isMobileScreenWidth(wsize.width);
    setIsMobileScreen(ismobile);
    if (fpOpen && !ismobile) {
      console.log('closing MFP');
      setFpOpen(false);
      setFpLeft('-100%');
    }
  }, [wsize]);

  return (
  <div className="lower-page-start">{/* need this lower-page-start to be unique on the page */}
  {/* mobile filter panel */}
        {/* MARK: Mobile filter accordion */
        /* FIXME: Abstract this into a proper component*/
      }
    <div className={`filterbar-current-label ${props.currentLabel ? "" : "is-hidden" }`}>
      <span>{props.currentLabel}</span>
    </div>

  <div
    className={fpOpen ? "" : "d-none"}
    id="mfPanel"
    style={{ left: `${fpLeft}` }}
    aria-hidden={!isMobileScreen}
  >
    <div className="blocker-d" onClick={hideMFP}></div>
    <div className="mfp-base">
      <div className="m-0 p-3 mfp-heading-row">
        <div className="mfp-rowfullcol">
          <div className="d-flex align-items-center">
            <i className="icon button-icon icon-sm icon-filter icon--black mr-2"></i>
            <div className="std-text-p2-sb">{labels.filtersButton}</div>
          </div>
          <div className="d-block">
            <button type="button"
              onClick={hideMFP}
              className="button"
            >
              {labels.close}
            </button>
          </div>
        </div>
      </div>

    { /* MARK: MobileFilterPillBar */
      /* FIXME: Abstract this into a proper component */
      (props.currentFilters && Object.keys(props.currentFilters).length > 0) &&

      <React.Fragment>
      <div className="m-0 px-3 py-1 mfp-heading-row-nb">
        <div className="mfp-rowfullcol">
            <div className="landing-page__filter-by mr-2">
              <div className="std-text-p4">{`${labels.filteredBy}:`}</div>
            </div>
            <div className="d-block">
              <button
                className="button"
                type="button"
                onClick={clearFiltersAndClosePanel}
              >
                {labels.clearFilters}
              </button>
            </div>
        </div>
      </div>
      <div className="m-0 pt-0 pb-1 px-1 mfp-heading-row-bb">
        <div className="mfp-rowfullcol">
          <div className="landing-page__current-filters pt-0">
            <div className="align-items-center">
              <div className="px-2">
                <div className="d-flex">
                  {
                    (Object.keys(props.currentFilters).length > 0) ?
                      Object.keys(props.currentFilters).map(function (key, idx) {
                        const filterValue = props.currentFilters[key];
                        const filterPillLabel = getFilterPillLabel(key, filterValue);
                        return (
                          <div className="filtered-by-pill" title={filterPillLabel} key={`key-filter-pill-${idx}`}>
                            { truncate(filterPillLabel, 20) }
                            <a className="ml-2 has-tooltip-arrow"
                              href="#"
                              aria-label="Remove filter"
                              data-tooltip="Remove filter"
                              onClick={(e) => {e.preventDefault(); props.clearFilter(key)}}
                            >
                              <span className="icon icon-sm button-icon icon-close-button-x icon--black"></span>
                              <span className="sr-only">Remove filter</span>
                            </a>
                          </div>
                        )
                      }) : ('')
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    }

      <div className="m-0">
        <div className="p-0">
          <FiltersAccordion
            accordionId="filtersAccordion"
            yearRange={yearRange}
            applyFilters={props.applyFilters}
            clearFilter={props.clearFilter}
            filters={props.currentFilters}
            aggregations={props.aggregations}
            count={props.count}
            collapseAllFunc={collapseAll}
            filterPaneConfigs={filterPaneConfigs.filter( paneConfig => paneConfig.type === "pane" )}
          />
        </div>
      </div>

    </div>
  </div>
  {/* end of mobile filter panel */}

  <div>
    {/* for mobile: Filters button */}
    {/* FIXME: Can this be abstracted into its own component? */}
    <div className="landing-filters-mobile d-block d-md-none">
      <div className="d-flex align-items-center mobile-filters-and-sortby">
        <div className="">
          <button onClick={openMFP} className="button">
            <div className="d-inline-block dropdown-label">
              <i className="icon button-icon icon-sm icon-filter icon--black mr-2"></i>
              {labels.filtersButton}{(Object.keys(props.currentFilters).length > 0 ? ` (${Object.keys(props.currentFilters).length})` : '')}
            </div>
          </button>
        </div>
        {
          filterPaneConfigs.some( (paneConfig, idx) => { 
            if ( paneConfig.type === "checkbox-control" )  {
              return (<div className="has-image-container" key={idx}>
            <label id="filter-images-label" className="checkbox filter-has-image is-size-7">
              <input type="checkbox" defaultChecked={props.currentFilters.images === true} id="filter-images" aria-labelledby="filter-images-label" onClick={ (event) => {
                  if ( props.currentFilters.images === true ) {
                    props.clearFilter('images')
                  } else {
                    props.applyFilters( {images: true }, [])
                  }
                } } /> {filterPaneConfigs.filter( paneConfig => paneConfig.type === "checkbox-control" )[0].label}
            </label>
          </div>)
            }
            else {
              return ''
            }
        }) }

        <div className="sort-div sort-div-mobile">
          <div className="sort-by-group">
            <div className="dropdown-left-label">{`${labels.sortBy}:`}&nbsp;</div>
            <div className="is-flex">
            <div id="filter-sort-cont-0" className="select is-small filter-sort">
              <select id="filter-sort-m" aria-labelledby="filter-sort-m" value={props.sortTerm} onChange={ event => props.changeSort({sortTerm: event.target.value })} >
                { config.searchConfig.sort.map( (sortConfig, idx) => {
                    return <option value={sortConfig.field} key={idx}>{sortConfig.label}</option>
                }) }
              </select>
            </div>
            <button className="button icon-button ml-1" id="search-interface-sort-toggle" title={`Sort results ${props.sortDir}`}>
              <img src="/assets/images/icon-sortdown.png" alt="sort" style={{transform:'none'}} />
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* end of mobile-only */}

    {/* MARK: FilterBarInputRow */}
    {/* FIXME: Abstract this into a control wrapper around FilterInput children */}

    <div id="collapseFiltersParent" className="d-none d-md-block border-bottom-sm" aria-hidden={isMobileScreen}>
      <div className={`landing-page__filter-bar ${(props.customClasses || '')} ${(props.barClasses || '')}`}>
        <div className="landing-page__filter-by">
          <h6 className="dropdown-label">{`${labels.filterBy}:`}</h6>
        </div>
        {
          filterPaneConfigs.map((paneConfig, idx) => {

            switch (paneConfig.type) {
              case 'pane':
                return <div className="landing-page__filter" key={`key-paneconfig-${paneConfig.name}`}>
                    <button
                      type="button"
                      className="btn btn-xpandr collapsed filters-pane-button"
                      id={`collapseFiltersButton-${paneConfig.name}`}
                      // data-toggle="collapse"
                      data-target={`#collapseFilters-${paneConfig.name}`}
                      aria-expanded="false"
                      aria-controls={`collapseFilters-${paneConfig.name}`}
                      onClick={ () => {
                        setOpenPanePre( prevOpen => ( prevOpen === paneConfig.name ? null : paneConfig.name ));
                      }}
                    >{paneConfig.label}</button>
                  </div>
              case 'checkbox-control':
                const clickHandler = (event) => {

                  if ( props.currentFilters.images === true ) {
                    props.clearFilter('images')
                  } else {
                    props.applyFilters( {images: true }, [])
                  }
                }

                return <div className="landing-page__filter" key={`key-paneconfig-${paneConfig.name}`}>
                  <label className="checkbox filter-has-image is-size-7">
                    <input type="checkbox" defaultChecked={props.currentFilters.images === true} id="filter-images" aria-labelledby="filter-images" onClick={ clickHandler } /> {paneConfig.label}
                  </label>
                </div>
              default:
                break;
            }
          })
        }

        <div className="landing-page__filter ml-auto">
          <div className="sort-div">
            <div className="sort-by-group">
              <div className="dropdown-left-label">{`${labels.sortBy}:`}&nbsp; </div>
              <div className="is-flex">
              <div id="filter-sort-cont-1" className="select is-small filter-sort">
                <select id="filter-sort" aria-labelledby="filter-sort" value={props.sortTerm} onChange={ event => props.changeSort({sortTerm: event.target.value })}>
                  { config.searchConfig.sort.map( (sortConfig, idx) => {
                    return <option value={sortConfig.field} key={idx}>{sortConfig.label}</option>
                  }) }
                </select>
              </div>
              <button id="search-interface-sort-toggle" className="button icon-button ml-1" title={`Sort results ${props.sortDir}`}>
                <img src="/assets/images/icon-sortdown.png" alt="sort" style={{ transform: props.sortDir === "desc" ? 'none' : 'rotate(180deg)' }} onClick={ event => props.changeSort({sortDir: props.sortDir === "desc" ? "asc" : "desc" })} />
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        filterPaneConfigs.filter( paneConfig => paneConfig.type === "pane" ).map((paneConfig, idx) => {
          return <div
              className={`collapse ${openPanePre === paneConfig.name || openPanePost === paneConfig.name ? "" : "collapsed"} ${openPane === paneConfig.name ? "is-active" : ""} search-filt-container ${(props.customClasses || '')} ${(props.rowCustomClasses || '')}`}
              id={`collapseFilters-${paneConfig.name}`}
              data-parent={`#collapseFiltersParent`}
              ref={refs[paneConfig.name]}
              style={openPane === paneConfig.name
                  ? { height: refs[paneConfig.name].current.scrollHeight }
                  : { height: "0px" }}
              key={`key-filter-panel-${paneConfig.name}`}
              onTransitionEnd={() => {
                // console.log('transition end; openPane (setting pre/post to) = '+openPane+'; openPanePre = '+openPanePre+'; openPanePost = '+openPanePost);
                setOpenPanePre(openPane);
                setOpenPanePost(openPane);
              }}
            >
              <div className="filters-flex-container">
                {
                paneConfig.filters.map(filterConfig => {
                  return <FilterControl
                      aggregations={props.aggregations}
                      filterConfig={filterConfig}
                      filters={props.currentFilters}
                      applyFilters={props.applyFilters}
                      clearFilter={props.clearFilter}
                      count={props.count}
                      collapseFunc={collapseAll}
                      key={`key-filter-control-${filterConfig.name}`}
                    />
                  })
                }
                <div className="is-hidden-mobile ml-auto pl-3 landing-filters-cell landing-filters-bt">
                  <button type="button"
                    className="button"
                    onClick={() => collapseAll()}
                    onTransitionEnd={(e) => {
                      // unless we stop propagation this will trigger the panel's transition-end event handler
                      e.stopPropagation();
                    }}
                  >
                    {labels.close}
                  </button>
                </div>
              </div>
            </div >
          })
      }

      { /* MARK: FilterPillBar */
        // FIXME: Fixes a problem on exhib pages where the pill bar appears even tho no filters engaged, but needs concreter fix
        ( showFilterPillBar ) &&

      <div className={`landing-page__filtered-bar landing-filters-bt ${(props.customClasses || '')} ${(props.barClasses || '')}`}>
        <div className="landing-page__filter-by">
          <div className="std-text-p2">{`${labels.filteredBy}:`}</div>
        </div>
        <div className="landing-page__current-filters align-items-center d-flex">
              <div className="px-0">
                <div className="d-flex">
                  {
                    (Object.keys(props.currentFilters).length > 0) ? // FIXME: This filter bit should be loaded from the config
                      Object.keys(props.currentFilters).filter((key,idx) => ![ "exhibition" ].includes(key) ).map((key, idx) => {
                        const filterValue = props.currentFilters[key];
                        const filterPillLabel = getFilterPillLabel(key, filterValue);
                        return (
                          <div className="filtered-by-pill" title={filterPillLabel} key={`key-filter-pill-${idx}`}>
                            { truncate(filterPillLabel, 20) }
                            <a className="ml-2 has-tooltip-arrow"
                              href="#"
                              aria-label="Remove filter"
                              data-tooltip="Remove filter"
                              onClick={(e) => {e.preventDefault(); props.clearFilter(key)}}
                            >
                              <span className="icon icon-sm button-icon icon-close-button-x icon--slate-gray"></span>
                              <span className="sr-only">Remove filter</span>
                            </a>
                          </div>
                        )
                      }) : ('')
                  }
                </div>
              </div>
              <div className="ml-auto">
                <button
                  className="button"
                  type="button"
                  onClick={props.clearFilters}
                >
                  {labels.clearFilters}
                </button>
              </div>
        </div>
      </div>
      }

  </div>
  </div>
  </div>
  )
}

export { FilterBar };