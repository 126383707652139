import React from 'react';

const EntityThumbnail = ({type}) => {
    const iconInfoFromType = (t) => {
        // return an object { icon classname, is circular?, offset }
        switch (t.toLowerCase()) {
            case 'file':
                return { classname: 'jd-icon-file', circular: false, offset: true };
                break;
            case 'city':
                return { classname: 'jd-icon-city-sq', circular: false, offset: false };
                break;
            default:
                return { classname: 'icon-jewelry', circular: false, offset: true };
                break;
        }
    };
    const iconInfo = iconInfoFromType(type);

    return <div
            className={`icon icon-results-list ${iconInfo.offset ? 'icon-boxed ' : 'icon-bleed '}${iconInfo.classname}`}
        ></div>
}

export default EntityThumbnail;