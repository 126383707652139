/*
searchConfig.js

Configuration objects for search + filter 
*/

import React, { createContext, useState } from 'react';

import {searchContextToQuery} from '../util.js';

export const AbstractSearchConfig = { // NB: This can be different per-page (eg, artwork page has different filters than archive page has different than search page)
    filters: [
        {
            name: 'type', // will be used in Pane definitions...
            type: 'term', // Describe the agg type? eg, is it a term (bucket.key) or a composite (bucket.key.label) or a date or a date-decade, etc
            agg: {}, // FIXME // Just the literal agg node you need to populate this
            agg_name: 'type',
            label: 'Type',
            allFacetsLabel: {
                '@none': 'All Types'
            },
            bucketNormalizer: (bucket) => { return { ...bucket, key: {label: bucket.key}, active: { doc_count: 999} } }
        },
        {
            name: 'material', 
            type: 'term', 
            agg: {},
            agg_name: 'materials',
            label: 'Materials',
            allFacetsLabel: {
                '@none': 'All Materials'
            },
            bucketNormalizer: (bucket) => { return bucket }

        },
        {
            name: 'theme', 
            type: 'term', 
            agg: {},
            agg_name: 'theme',
            label: 'Theme',
            allFacetsLabel: {
                '@none': 'All Themes'
            }
        },
        {
            name: 'date-decades', 
            type: 'term', 
            agg: {},
            agg_name: 'date',
            label: 'Date',
            allFacetsLabel: {
                '@none': 'All Dates'
            }
        },
        {
            name: 'date-exact', 
            type: 'date-entry', 
            label: 'Date',
        },
        {
            name: 'images', 
            type: 'checkbox', 
            label: 'Has Image',
        },
        {
            name: 'artists', 
            type: 'term', 
            agg: {},
            agg_name: 'artist',
            label: 'Artist',
            allFacetsLabel: {
                '@none': 'All Artists'
            }
        },
    ],
    filterPanes: [
        // Do we adapt GOKM to JDCRP...
        {
            name: 'object',
            type: 'container', // vs whatever we'd do for a two-level ?
            label: 'Object',
            filters: [ 'type', 'materials', 'theme' ]
        },
        {
            name: 'timeframe',
            type: 'container', // vs whatever we'd do for a two-level ?
            label: 'Timeframe',
            filters: [ 'date-decades', 'date-exact' ]
        },
        {
            name: 'maker',
            type: 'container', // vs whatever we'd do for a two-level ?
            label: 'Maker',
            filters: [ 'date-decades', 'artists' ]
        },
        // ...VS...
        // Adapt JDCRP to GOKM 
        {
            name: 'filters',
            type: 'select-row',
            filters: [ 'type', 'materials', 'theme', 'date-decades' ] // would have to add the date-exact as a default component of date-decades (but that's probs fine)
        }
        // Another option would be to just use the abstract elements (FilterBar, DateInputPanel, etc) as a package to re-compose the GOKM search
        // We'd have to put react bundling directly in, but that was most likely to happen anyway

    ],
    sort: [
        {
            label: 'by Title',
            term: {}
        },
        {
            label: 'by Date',
            term: {}
        },
        {
            label: 'by Creator',
            term: {}
        }
    ],
    searchQuery: (state) => { /* Implementers are mostly on their own handling this state var? */ } 
}

export const searchPageSearchConfig = { // NB: This can be different per-page (eg, artwork page has different filters than archive page has different than search page)
    filters: [

    ],
    filterPanes: [

    ],
    sort: [

    ],
    searchQuery: searchContextToQuery
}