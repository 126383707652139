import React, { useState, useEffect, useRef, useContext } from 'react';
import DOMPurify from 'dompurify';

import { MultiCarouselFlex } from './multiCarousel.js';
import { SearchConfigContext } from '../context/searchConfigContext.js';
import { FALLBACK_IMAGE_SIZES, getFallbackImageUrl, sizeResettingErrorHandler } from "../util";

const QuickViewItem = (props) => {

    return (
        <div id={props.id}
            // note - mode and myWidth are added to the props by MultiCarousel
            className={`sliding-qv-item results-grid-item ${props.mode === 'slideshowMode' ? 'mc-slideshow-item' : ''}`}
            style={{ width: (props.mode === 'slideshowMode' && props.myWidth >= 500 ? props.myWidth + 'px' : 'auto') }}
            tabIndex="0"
        >
            <div className="results-grid-item-image centered mb-5"
                style={props.tallHt && props.tallHt > 0 ? {} : {}}
            >
            <img className={`thumb ${props.mode === 'slideshowMode' ? 'thumb-ctr' : ''} ${props.tallMode ? 'qv-tall-thumb-img' : ''}`}
                loading="lazy"
                alt={`Artwork: ${props.title}`}
                key={props.imageUrl}
                srcSet={ (!props.imageUrl || props.imageUrl === "") ? "/assets/images/no-img-thumb-600.png" : `${props.imageUrl} 1x, ${props.imageUrl2x} 2x, ${props.imageUrl3x} 3x` }
                onError={sizeResettingErrorHandler('full','/assets/images/image-404-600.png 1x')}
                {...props.imgSizeParams}
            />
            </div>
            <div className='sliding-qv-textgroup columns px-5'>
                <div className='sliding-qv-text column is-4'>
                    <div className='std-text-p2-sb pb-1'>{props.artist}</div>
                    <div className='std-text-h3 pb-1'>{props.title}, {props.year}</div>
                    <div className='std-text-p2 pb-1 d-none'>{props.credit}</div>
                    <div className={`std-text-p2 pb-1 ${ props.catalogNumber ? '' : 'd-none' }`}>{`Catalogue Raisonné ${props.catalogNumber}`}</div>
                    <div className='std-text-p3 pb-1'>{props.identifier}</div>
                </div>
                <div className={`sliding-qv-text column is-4 mr-5 ${props.collection ? 'sliding-qv-leftborder' : '' }`}>
                    <div className={`std-text-p3-sb pb-1 ${props.collection ? '' : 'd-none'}`}>Current collection:</div>
                    <div className='std-text-p2 pb-1'>{props.collection}</div>
                    <div className='std-text-p3 pb-1'>{props.copyright}</div>
                </div>
                <div className={`sliding-qv-text pt-1 column is-4 mr-5 ${ props.isReference ? 'd-none' : '' }`}>
                    <button
                      type="button"
                      className="btn btn-outline-white"
                      onClick={() => {
                        switch (true) {
                            case props.itemId.search(/^https?:\/\//) === 0:
                                window.open(props.itemId,'_blank').focus()
                                break
                            default:
                                let href = props.itemId.startsWith('/') ? props.itemId : `/${props.itemId}`
                                window.open(href)
                        }
                      }}
                    >View Full Record</button>
                    <button
                      type="button"
                      className={`btn btn-outline-white mt-1 ${ props.spanishLink ? '' : 'd-none' }`}
                      onClick={() => {
                        switch (true) {
                            case props.itemId.search(/^https?:\/\//) === 0:
                                window.open(props.spanishLink,'_blank').focus()
                                break
                            default:
                                let href = props.itemId.startsWith('/') ? props.itemId : `/${props.itemId}`
                                window.open(href)
                        }
                      }}
                    >en español</button>
                </div>
                <div className={`sliding-qv-text pt-1 column is-4 mr-5 ${ props.isReference && props.websiteLink.length > 0 ? '' : 'd-none' }`}>
                    <a className="std-text-p2-sb has-text-white" target="_blank" href={props.websiteLink.length > 0 ? props.websiteLink[0] : '' } style={{textDecoration: 'none'}}>View in Current Collection <span className="icon icon-arrow-rt1 icon--white"></span></a>
                </div>
            </div>
        </div>
    )
}

const QuickViewer = (props) => {

    const generateRandomString = () => {
        return Math.random().toString(32).slice(2) + Date.now();
    }

    const config = useContext(SearchConfigContext);

    const [ qvData, setQvData ] = useState({...props.qvData, 'datasetSig': generateRandomString()});
    const qvp = useRef(null);
    const [ qvOpen, setQvOpen ] = useState(false);
    const [ qvOnscreen, setQvOnscreen ] = useState(false);
    const [ qvTop, setQvTop ] = useState('0');
    // const [ currentQVItem, setCurrentQVItem ] = useState(null);
    const [ qvStoredActiveElement, setQvStoredActiveElement ] = useState(null);
    const [ blockerTransparent, setBlockerTransparent ] = useState(true);

    let focusables = null;

    // const [ preloadedImgs, setPreloadedImgs ] = useState([]);
    // usePreloadImages(preloadedImgs);

    const qvDataUnsetDelay = 500;

    const qvTabListener = (e) => {
        if (!focusables) { return; }
        //console.log(focusables);
        const KEYCODE_TAB = 9;
        const firstFocusableEl = focusables[0];
        const lastFocusableEl = focusables[focusables.length - 1];
        const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
        if (!isTabPressed) { return; }
        const qvIsOpen = !qvp.current.classList.contains('d-none');
        if (!qvIsOpen) {
          //console.log('tab but QV is not open');
          return;
        }

        if (e.shiftKey) /* shift + tab */ {
          if (document.activeElement === firstFocusableEl) {
            //console.log('-- shift-tab -- active is el #1');
            lastFocusableEl.focus();
            e.preventDefault();
          }
        } else /* tab */ {
          if (document.activeElement === lastFocusableEl) {
            //console.log('-- tab -- active is el #2');
            firstFocusableEl.focus();
            e.preventDefault();
          }
        }
        // if focus not within the QV, grab it
        if (!document.activeElement || document.activeElement.classList.contains('rel-item-link1')) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
    }

    useEffect(() => {
        // when we've transitioned the QV offscreen, then we need to do some cleanup
        if (qvOpen && !qvOnscreen) {
          // delay hiding because first we'll be transitioning
          setTimeout(() => {
            document.removeEventListener('keydown', qvTabListener);
            // NOTE: we don't need to set the focus in this version of quickview; if we did, it could cause an annoying scroll behavior when closing the QV
            //qvStoredActiveElement?.focus();
            // itemMouseUpHandler in relationshipsTab sets this when clicking on the item, undo it here in case keyboard is used:
            //qvStoredActiveElement?.classList.toggle('focus-outline-none', false);
            setQvOpen(false);
          //}, qvDataUnsetDelay + 200);
          }, 250);
        }
    }, [qvOnscreen]);

    useEffect(() => {
        // console.log('useEffect: qvOpen chgd. to '+qvOpen);
        // after the QV is opened, set shim stuff [x] set up focus stuff and slide the QV panel in from right
        if (qvOpen) {
          // transition in the QV panel
          setTimeout(() => {
            // transition in:
            setQvOnscreen(true);
            // add tab-listener to qvp, so the focus is 'trapped' inside it
            if (focusables === null) {
              focusables = qvp.current.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
            }
            document.addEventListener('keydown', qvTabListener);
          }, 20);
        }
    }, [qvOpen]);

    useEffect(() => {
        // when qv data contains data, we open the qv panel
        if (props.qvData && Object.keys(props.qvData).length > 0) {
            rememberActiveElement();    // concerns the focus
            // console.log('got qv data; calling setQvData and openQVPanel');
            if (false && props.preloadClickedImg) {
                // console.log('preload img: imageUrl: '+props.qvData.imageUrl);
                // const imgToLoadSrc = props.qvData.imageUrl.replace('square/!240,240', 'full/,600').replace(',242', ',600');
                // setPreloadedImgs([...preloadedImgs, imgToLoadSrc]);
                setTimeout(() => {
                    // this delay helps avoid flicker in the qv panel (why?)
                    setQvData({...props.qvData, 'datasetSig': generateRandomString()});
                }, 350);
            } else {
                setQvData({...props.qvData, 'datasetSig': generateRandomString()});
            }
            openQVPanel();
        }
    }, [props.qvData]);

    const rememberActiveElement = () => {
        // remember where the focus was, so we can go back to it when the QV is closed:
        // console.log('active element = ');
        // console.log(document.activeElement);
        setQvStoredActiveElement(document.activeElement);
    }

    const openQVPanel = () => {
        // set the QVP's top coordinate
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const qvt = Math.max(props.minY ?? 0, scrollTop);
        // console.log('scrollTop: ' + scrollTop + '; tabContentTop: ' + tabContentTop + '; footerTop: ' + footerTop + '; qv top = ' + qvt + '; topStuffHt = ' + topStuffHt);
        setQvTop(qvt);
        // console.log('setting qv open');
        setQvOpen(true);
        // increase the blocker's opacity:
        setBlockerTransparent(false);
        // useEffect above will handle the rest
    }

    // close the quick-view panel
    const hideQV = () => {
        // console.log('hideQV');
        setQvOnscreen(false);
        // decrease the blocker's opacity:
        setBlockerTransparent(true);
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        body.style.height = '';
        body.style.overflowY = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        // last thing; wait a bit b4 unsetting the data -- to avoid change of view until AFTER hiding the QV panel
        setTimeout(() => {
            // console.log('setting empty qv data');
            setQvData({});
        }, qvDataUnsetDelay);
    }

    return (
    <div className={`qvPanel ${qvOpen ? '' : 'd-none'} ${qvOnscreen ? 'qv-onscreen' : ''}`}
        id={props.myId}
        ref={qvp}
        style={{top: 20}}
    >
        <div className={`blocker ${blockerTransparent ? 'blocker-transparent' : ''}`} onClick={hideQV}></div>
        <div className="qv-base">
            <button
                className="gok-btn-plain qv-close-x"
                onClick={hideQV}
            >
                <span className="icon icon-quickview-close icon--white icon-3"></span><span className="sr-only">Close this view</span>
            </button>
            <div className='qv-viewer-container px-3' style={{minHeight: `50vh`}}>
            {
                qvData && qvData.myList &&
                <MultiCarouselFlex
                    eltIdPrefix={props.myId}
                    buttonColorClass={`icon--white`}
                    selectedIdx={qvData.listIdx}
                    tallMode={true}
                    mode={`slideshowMode`}
                    initialSlideTransition={false}
                    preload={false}
                    datasetSig={qvData.datasetSig}  // this lets us notice when the data changes
                >
                {
                    qvData.myList.map((item, idx1) => {
                        if (qvData.dataFormat === 'relationships') {
                            const mapping = props.relationshipsMapping
                            const imageUrl = item.thumbnail ?? getFallbackImageUrl(item,FALLBACK_IMAGE_SIZES.LARGE)
                            const imageUrl1x = imageUrl.replace('square/!240,240', 'full/,600').replace(',242', ',600');
                            const imageUrl2x = imageUrl1x.replace(',600', ',1200');
                            const imageUrl3x = imageUrl1x.replace(',600', ',1800');
                            const isReference = mapping.isReference !== undefined && mapping.isReference(item)
                            return (
                                <QuickViewItem
                                    artist={item.artist}
                                    title={item.title}
                                    year={item.year}
                                    credit={item.credit}
                                    identifier={item.identifier}
                                    collection={item.collection}
                                    copyright={item.copyright}
                                    catalogNumber={item._source?.caption?.catalog_number?.join(', ') ?? ''}
                                    isReference={isReference}
                                    websiteLink={item._source?.site_link?.home ?? []}
                                    spanishLink={item.spanishLink}
                                    itemId={item.id}
                                    key={idx1}
                                    id={`${props.myId}-${idx1}`}   // note: items in MultiCarouselFlex MUST have a unique id
                                    imageUrl={imageUrl1x}     // required in each item if the MCF's preload property is set
                                    imageUrl2x={imageUrl2x}
                                    imageUrl3x={imageUrl3x}
                                    imgSizeParams={item.thumbnail.endsWith(".svg") ? {height: "600", width:"600"} : {} }
                                    tallHt={600}
                                    tallMode={true}
                                    containerId={props.myId}
                                />
                            )
                        } else if (qvData.dataFormat === 'searchResults') {
                            const mapping = config.mapping;
                            const imageUrl = mapping.imageUrl(item) ?? getFallbackImageUrl(item,FALLBACK_IMAGE_SIZES.LARGE);
                            const imageUrl1x = imageUrl.replace('square/!240,240', 'full/,600').replace(',242', ',600') ?? '';
                            const imageUrl2x = imageUrl1x.replace(',600', ',1200');
                            const imageUrl3x = imageUrl1x.replace(',600', ',1800');
                            const label = DOMPurify.sanitize(mapping.label(item), {ALLOWED_TAGS: []});
                            const makers = DOMPurify.sanitize(mapping.makers(item), {ALLOWED_TAGS: ['br']});
                            const dateLabel = mapping.dateLabel(item);
                            const isReference = mapping.isReference !== undefined && mapping.isReference(item)
                            return (
                                <QuickViewItem
                                    artist={makers}
                                    title={label}
                                    year={dateLabel}
                                    credit={mapping.credit(item)}
                                    identifier={mapping.accession(item)}
                                    collection={mapping.currentOwner(item)}
                                    copyright={mapping.copyright(item)}
                                    catalogNumber={item._source.caption?.catalog_number?.join(', ') ?? ''}
                                    isReference={isReference}
                                    websiteLink={item._source.site_link?.home ?? []}
                                    itemId={item._id}
                                    key={idx1}
                                    idx={idx1}
                                    id={`${props.myId}-${idx1}`}   // note: items in MultiCarouselFlex MUST have a unique id
                                    imageUrl={imageUrl1x}
                                    imageUrl2x={imageUrl2x}
                                    imageUrl3x={imageUrl3x}
                                    imgSizeParams={mapping.imageUrl(item) ? {} : {height: "600", width:"600"}}
                                    tallHt={600}
                                    tallMode={true}
                                    containerId={props.myId}
                                />
                            )
                        }
                    })
                }
                </MultiCarouselFlex>
            }
            </div>
        </div>
    </div>
    )
}

export { QuickViewer };