import React, { useState, useEffect, useRef } from 'react';

// see https://www.javascriptstuff.com/react-image-gallery/
const imagesLoaded = (parentNode) => {
    const imgElements = [...parentNode.querySelectorAll("img")];
    // console.log(imgElements);
    for (let i = 0; i < imgElements.length; i += 1) {
        const img = imgElements[i];
        if (!img.complete) {
            return false;
        }
    }
    return true;
}

const PreloadedImageList = (props) => {
    const [ doneLoading, setDoneLoading ] = useState(false);
    const [ doitTimeout, setDoitTimeout ] = useState(null);

    const myRef = useRef(null);

    useEffect(() => {
        // cleanup function:
        return () => {
            if (doitTimeout) {
                clearTimeout(doitTimeout);
            }
        };
    }, []);

    // when done loading, call the whenDone callback:
    useEffect(() => {
        console.log('doneLoading change');
        if (doneLoading) {
            setDoitTimeout(setTimeout(() => {
                console.log('WHEN: DONE.');
                props.whenDone();
            }, 1000));  // wait a little bit because we've seen a problem of singletonMode being set to true (in the 'children' useEffect) AFTER preloading sets it false
        }
    }, [doneLoading]);

    useEffect(() => {
        checkIfDoneLoading();
    }, [myRef.current]);

    const checkIfDoneLoading = () => {
        const allLoaded = imagesLoaded(myRef.current);
        if (allLoaded && !doneLoading) {
            // trigger the completion callback
            console.log('setDoneLoading to '+allLoaded);
            setDoneLoading(true);
        }
    }

    useEffect(() => {
        console.log('imgUrls changed');
        if (myRef.current) {
            const allLoaded = imagesLoaded(myRef.current);
            setDoneLoading(allLoaded);
        }
    }, [props.imgUrls]);

    return (
        <div ref={myRef} style={{display: 'none'}}>
            {props.imgUrls.map((d, i) => {
                return (
                    <img src={d} key={i} onLoad={checkIfDoneLoading} onError={checkIfDoneLoading} />
                )
            })}
        </div>
    )
}

export { PreloadedImageList };