function getAppVersion() {

	// Find and return the content attr of the first <meta name="version" content="version_no"> tag 	
	var metaTags = document.getElementsByTagName('meta');

	for (var tag of metaTags) {
		if ( tag.getAttribute('name') === "version" ) {
			return tag.getAttribute('content'); 
		}
	}

}

function getEsUrl() {
	const endpointTag = document.querySelector('meta[name="endpoint"]');
	const indexNameTag = document.querySelector('meta[name="index_name"]');

	if ( endpointTag && indexNameTag ) {
		return `${endpointTag.content}/${indexNameTag.content}/_search`
	} else {
		return 'https://596ZqwceSt:9wfYU5TGKebEr63MWJqv7@gokm-exhibs-dev-3584801431.us-east-1.bonsaisearch.net:443/gokm/_search'
	}

}

let version = getAppVersion();

var pageSize = 50
var esUrl = getEsUrl();

if ( version === "stage" ) {
	esUrl = esUrl.replace( '/gokm/', '/gokm-stage/');
}

function sortByKey(a,b){
  if(a.key < b.key) return -1;
  if(a.key > b.key) return 1;
  return 0;
}

function queryStringEscape( queryString ) {
	// Elastic throws errors if we don't escape unmatched quote chars in query_string
	if ( queryString.includes("\"") && queryString.match(/\"/g).length % 2 != 0 ) {
		// .. so escape one of them
		return queryString.replace(/"/,'\\"')
	} 
	return queryString 
}

export const esQueries = {

	buildFilterQuery: function( meta_type, 
								type = 'all', 
								material = 'all', 
								collection= 'all', 
								theme= 'all', 
								date = 'all', 
								dateBegin = 0, 
								dateEnd = 0, 
								people= 'all', 
								role = 'all', 
								hasImages = true, 
								page = 0, 
								sortField = 'title', 
								sortOrder = 'asc', 
								gokFilter = false, 
								aggregation = false, 
								hierarchy = 'all', 
								query_string = '',
								exhibition = 'all',
								maker_role = 'all',
								exhibition_role = 'all',
								exhibition_organizer = 'all',
								gokm_owned = true,
								box = 'all',
								folder = 'all' ) {

		if (query_string === '') { query_string = null; }
		if (type === '' || type === 'all') { type = null; }
		if (material === '' || material === 'all') { material = null; }
		if (collection === '' || collection === 'all') { collection = null; }
		if (theme === '' || theme === 'all') { theme = null; }
		if (date === '' || date === 'all') { date = null; }
		if (people === '' || people === 'all') { people = null; }
		if (role === '' || role === 'all') { role = null; }
		if (exhibition === '' || exhibition === 'all') { exhibition = null; }
		if (maker_role === '' || maker_role === 'all') { maker_role = null; }
		if (exhibition_role === '' || exhibition_role === 'all') { exhibition_role = null; }
		if (exhibition_organizer === '' || exhibition_organizer === 'all') { exhibition_organizer = null; }
		if (hierarchy === '' || hierarchy === 'all') { hierarchy = null; }
		if (box === '' || box === 'all') { box = null; }
		if (folder === '' || folder === 'all') { folder = null; }

		var sortObj = [];

		if (sortField === '_script.venue_count') {
			sortObj = [ { _script: { type: "number", script: "( doc['venues.id'].length ?: 0 )", order: sortOrder } }, { 'label.keyword': {order: 'asc'} } ]					
		} else {
			sortObj = [ { [sortField]: {"order":sortOrder} }	]
		}

		if ( !Array.isArray( meta_type ) ) {
			meta_type = [ meta_type ];
		}

		var query = {
		  "from": page*pageSize,
		  "size": pageSize,
		  "_source": {
		  	"excludes": ["_raw","note","inscription"]
		  },
		  "query": {
		    "bool": {
		      "must": [
		        {
		          "terms": {
		            "type": meta_type,
		          }
		        }
		      ]
		    }
		  },
		  post_filter: {
		  	bool: {
		  		must: [],
		  		must_not: []
		  	}
		  },
	    "sort" : sortObj,
			"aggs": {
			    "materials": {
			      "terms": {
			        "field": "classified_as",
			        "size": 500
			      },
		        "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        }
			    },
			    "collection": {
			      "terms": {
			        "field": "collection",
			        "size": 50
			      },
		        "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        }
			    },
			    "artist": {
			      "terms": {
			        "field": "artist_label.keyword",
			        "size": 500,
			        "order": { "_key": "asc" } 
			      },
		        "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        }
			    },
			    "artist_is_gok": {
			      "terms": {
			        "field": "artist_is_gok"
			      },
		        "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        }
			    },
			    "date": {
			    	"terms": {
							"size": 40, 
							"script": {
								"lang": "painless",
								"source": "if (!doc['timespan.begin_of_the_begin'].empty) { return doc['timespan.begin_of_the_begin'].value.getYear() - doc['timespan.begin_of_the_begin'].value.getYear() % 10; } else { return 'Undated'; }"
							}	
						},
		        "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        }
			    },
			    "theme": {
			      "terms": {
			        "field": "theme",
			        "size": 500
			      },
		        "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        }
			    },
			    "type": {
			      "terms": {
			        "field": "item_type",
			        "exclude": [ "Works of art" ],
			        "size": 500
			      },
		        "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        }
			    },
			    "role": {
			      "terms": {
			        "field": "role.label",
			        "size": 500,
			        "exclude": [ "origination source", "origination_source" ]
			      },
		        "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        }
			    },
			    "meta_type": {
			    	"terms": {
			    		"field": "type",
			    		"size": 10,
			    		"exclude": [ "archive_component", "archive" ]
			    	},
		        "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        }
			    }


			  }

		}

		// Our search field ranks
		let fields = [ "label^5", "artist_label^3", "alt_label^5",
		               "note^3", "transcript^3",
		               "inscription", "accession" ];

		if (query_string !== null) {

			query.query.bool.must.push({ "query_string": { "query": queryStringEscape( query_string ), "fields": fields, "fuzziness": 2 } });			

		}

		if (aggregation) {
			query.size = 1;
		}

		if (hasImages) {

			let filterTerm = { exists: { field: "representation.primary" } }
			query.post_filter.bool.must.push( filterTerm );

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 
		}

		if (type !== null) {
			let filterTerm = { term: { item_type: type } };
			query.post_filter.bool.must.push( filterTerm );

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}

		if (material !== null) {
			let filterTerm = { term: { classified_as: material } };
			query.post_filter.bool.must.push( filterTerm );

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}

		if (collection !== null) {
			let filterTerm = {term:{'collection':collection}};
			query.post_filter.bool.must.push( filterTerm );

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}

		if (theme !== null){
			let filterTerm = { term: { theme: theme } };
			query.post_filter.bool.must.push( filterTerm );

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}

		if (date !== null){

			// NB: must vs must_not
			if ( date === 'none' ) {

				let filterTerm = { exists: { field: "timespan.begin_of_the_begin" } }
				query.post_filter.bool.must_not.push(filterTerm)

				Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

			} else {
				let filterTerm = { 
											range: { "timespan.begin_of_the_begin": {
			              			gte: parseInt(date),
			              			lte: parseInt(date)+9
			           					}
			          			}
	        					}

				query.post_filter.bool.must.push(filterTerm);
				Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

			}


		}

		if (dateBegin > 0 || dateEnd > 0) {
			var rangeFilter = {
							"range": {
								"timespan.begin_of_the_begin": { }
								}
							}
			if ( dateBegin > 0 ) {
				rangeFilter.range["timespan.begin_of_the_begin"].gte = dateBegin
			}

			if ( dateEnd > 0 ) {
				rangeFilter.range["timespan.begin_of_the_begin"].lte = dateEnd	
			}

			query.post_filter.bool.must.push( rangeFilter );

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( rangeFilter )
				}) 

		}

		if (people !== null){
			let filterTerm = {term:{"artist_label.keyword":people}};
			query.post_filter.bool.must.push( filterTerm );

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}

		if (role !== null){
			let filterTerm = {term:{"role.label": role}};
			query.post_filter.bool.must.push( filterTerm );

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}

		if ( exhibition !== null ) {
			// This becomes part of the main query block since `exhibition` is only used on captive pages
			let filterTerm = { terms: { 'events.id': exhibition } }
			query.query.bool.must.push( filterTerm )
		}

		if ( exhibition_organizer !== null ) {

		 	let filterTerm = { terms: { 'venues.organizer.link': `http://data.okeeffemuseum.org/${exhibition_organizer}` } }
			query.post_filter.bool.must.push( filterTerm )

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 
		}

		if ( maker_role !== null ) {
			let filterTerm = { term: { 'role.id': maker_role } }
			query.post_filter.bool.must.push( filterTerm )

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}

		if ( exhibition_role !== null ) {
			let filterTerm = { term: { 'venues.role_key': exhibition_role } }
			query.post_filter.bool.must.push( filterTerm )

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}

		if ( gokFilter ){
			let filterTerm = {term:{"artist_is_gok":true}};
			query.post_filter.bool.must.push( filterTerm );

			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}

		if ( hierarchy !== null ) {

			query.aggs.box = { terms: { field: "container.box" }, "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        } };
			query.aggs.folder = { terms: { field: "container.folder" },
		        "aggs": {
		        	"active": { filter: { bool: { must: [] } } }
		        } };

			query.query.bool.must.push( {term: { "hierarchy" : hierarchy } });

		}

		if ( box !== null ) {
			let filterTerm = {term:{"container.box": box}}

			query.post_filter.bool.must.push(filterTerm)
			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}

		if ( folder !== null ) {
			let filterTerm = {term:{"container.folder": folder}}

			query.post_filter.bool.must.push(filterTerm)
			Object.keys( query.aggs ).forEach( aggKey => {
					query.aggs[aggKey].aggs.active.filter.bool.must.push( filterTerm )
				}) 

		}
		return query;

	},

	search: function({ filters = {
						meta_type: 'all',
						type: 'all', 
						materials: 'all', 
						collection: 'all',
						theme: 'all', 
						date: 'all', 
						exhibition: 'all',
						role: 'all',
						exhibition_role: 'all',
						exhibition_organizer: 'all',
						dateBegin: 0,
						dateEnd: 0,
						artist: 'all', 
						images: false,
						gokFilter: false,
						gokmOwned: true
					},
					page = 0, 
					subquery = 'all',
					sortField = '_score', 
					sortOrder = 'desc', 
					query_string = '' }, aggregation = false 	) {
		
		let object_types = [ "archive_item", "art", "personal", "materials", "library" ] 
		let object_types_plus_refs = object_types.concat([ "art-ref" ])
		let exhibition_types = [ "exhibition" ]
		let person_types = [ "actor" ]

		var search_types = [];

		switch (subquery) {
			case 'actor':
				search_types = person_types;
				break;
			case 'exhibition':
				search_types = exhibition_types;
				break;
			default:
				search_types = filters.gokmOwned ? object_types : object_types_plus_refs;
				break
		}

		if (filters.meta_type && filters.meta_type !== 'all') {
			search_types = [ filters.meta_type ]
		}

		return this.buildFilterQuery( search_types, filters.type, null, null, filters.theme, filters.date, filters.dateBegin, filters.dateEnd, filters.artist, null, filters.images ?? false, page, sortField, sortOrder, false, aggregation, null, query_string, filters.exhibition, filters.role, filters.exhibition_role, filters.exhibition_organizer)

	},

	archive_component: function( { filters = {
						type: 'all', 
						materials: 'all', 
						collection: 'all',
						theme: 'all', 
						date: 'all', 
						dateBegin: 0,
						dateEnd: 0,
						artist: 'all', 
						images: true,
						gokFilter: false
					},
					page = 0, 
					subquery = 'all',
					sortField = 'label.keyword', 
					sortOrder = 'asc' }, aggregation = false ){

		let query = this.buildFilterQuery("archive_item", filters.type, filters.materials, filters.collection, filters.theme, filters.date, filters.dateBegin, filters.dateEnd, filters.artist, null, filters.images ?? false, page, sortField, sortOrder, filters.gokFilter, aggregation );
		query._source.excludes.push("events")
		query._source.excludes.push("role")
		query._source.excludes.push("production_technique")
		query._source.excludes.push("location")

		return query
	},

	archive_children: function({ filters = {
						type: 'all', 
						materials: 'all', 
						collection: 'all',
						theme: 'all', 
						date: 'all', 
						dateBegin: 0,
						dateEnd: 0,
						artist: 'all',						
						hierarchy: 'all', 
						images: false,
						gokFilter: false,
						box: 'all',
						folder: 'all'

					},
					page = 0, 
					subquery = 'all',
					sortField = 'label.keyword', 
					sortOrder = 'asc' }, aggregation = false) {

		return this.buildFilterQuery("archive_item", filters.type, filters.materials, filters.collection, filters.theme, filters.date, filters.dateBegin, filters.dateEnd, filters.artist, null, filters.images ?? false, page, sortField, sortOrder, filters.gokFilter, aggregation, filters.hierarchy, '', 'all', 'all', 'all', 'all', true, filters.box, filters.folder );

	},

	art: function( { filters = {
						type: 'all', 
						materials: 'all', 
						theme: 'all', 
						date: 'all', 
						dateBegin: 0,
						dateEnd: 0,
						artist: 'all', 
						images: true,
						gokFilter: false
					},
					page = 0, 
					subquery = 'all',
					sortField = 'label.keyword', 
					sortOrder = 'asc' }, aggregation = false ) {

		var activeFilters = { ...filters };

		if ( subquery === "gok" ) {
			activeFilters.gokFilter = true;
		} 

		if ( subquery === "photographs") {
			activeFilters.type = "Photographs";
		}

		if ( aggregation ) {
			activeFilters.images = false;
		}

		let query = this.buildFilterQuery("art", activeFilters.type, filters.materials, null, filters.theme, filters.date, filters.dateBegin, filters.dateEnd, filters.artist, null, activeFilters.images ?? false, page, sortField, sortOrder, activeFilters.gokFilter, aggregation );
		
		query._source.excludes.push("events")
		query._source.excludes.push("role")
		query._source.excludes.push("production_technique")
		query._source.excludes.push("location")
		
		return query
	},

	personal: function({ filters = {
						type: 'all', 
						materials: 'all', 
						theme: 'all', 
						date: 'all', 
						dateBegin: 0,
						dateEnd: 0,
						artist: 'all', 
						images: true,
						gokFilter: false
					},
					page = 0, 
					subquery = 'all',
					sortField = 'label.keyword', 
					sortOrder = 'asc' }, aggregation = false ){

		var types;
		switch (subquery) {
			case '':
			case 'all':
			case 'search':
				types = [ "personal" ,"library", "materials" ]
				break;

			case 'personal':
				types = [ "personal" ]
				break;

			case 'book':
				types = [ "library" ]
				break;

			case 'materials':
				types = [ "materials" ]
				break;

			default:
				types = []
				break;

		}
		return this.buildFilterQuery( types, filters.type, filters.materials, null, filters.theme, filters.date, filters.dateBegin, filters.dateEnd, filters.artist, null, filters.images ?? false, page, sortField, sortOrder, filters.gokFilter, aggregation );

	},

	materials: function( { filters = {
						type: 'all', 
						materials: 'all', 
						theme: 'all', 
						date: 'all', 
						dateBegin: 0,
						dateEnd: 0,
						artist: 'all', 
						images: true,
						gokFilter: false
					},
					page = 0, 
					subquery = 'all',
					sortField = 'label.keyword', 
					sortOrder = 'asc' }, aggregation = false ){

		return this.buildFilterQuery("materials", filters.type, filters.materials, null, filters.theme, filters.date, filters.dateBegin, filters.dateEnd, filters.artist, null, filters.images ?? false, page, sortField, sortOrder, filters.gokFilter, aggregation );

	},

	library: function( { filters = {						
						type: 'all', 
						materials: 'all', 
						theme: 'all', 
						date: 'all', 
						dateBegin: 0,
						dateEnd: 0,
						artist: 'all', 
						images: false,
						role: 'all',
						gokFilter: false
		}, page, subquery = 'all', sortField = 'label.keyword',sortOrder}, aggregation = false ){
		
		console.log(filters.materials)
		return this.buildFilterQuery("library", null, filters.materials, null, null, filters.date, filters.dateBegin, filters.dateEnd, null, null, null, page, sortField, sortOrder, filters.gokFilter );

	},

	exhibition: function( { filters = {						
						type: 'all', 
						materials: 'all', 
						theme: 'all', 
						date: 'all', 
						dateBegin: 0,
						dateEnd: 0,
						artist: 'all', 
						images: false,
						role: 'all',
						gokFilter: false
		}, page, subquery = 'all', sortField = 'label.keyword',sortOrder}, aggregation = false ){
		
		return this.buildFilterQuery("exhibition", null, filters.materials, null, null, filters.date, filters.dateBegin, filters.dateEnd, filters.artist, null, null, page, sortField, sortOrder, filters.gokFilter );

	},

	actor_list: function( { filters = {
						type: 'all', 
						materials: 'all', 
						theme: 'all', 
						date: 'all',
						dateBegin: 0, 
						dateEnd: 0, 
						artist: 'all', 
						images: false,
						role: 'all',
						gokFilter: false
					},page,sortField = 'label.keyword',sortOrder,gokFilter } ){

		return this.buildFilterQuery("actor", filters.type, filters.materials, null, null, null, null, null, null, filters.role, null, page, sortField, sortOrder, gokFilter );

	}

}

function searchQuery( query ) {

	return new Promise( (resolve, reject) => {

		var request = new XMLHttpRequest();
	
		request.open('POST', esUrl, true);
		request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

		request.setRequestHeader('Accept', 'application/json');
		request.send(JSON.stringify(query));

		request.onload = function() {
		  if (this.status >= 200 && this.status < 400) {

		    // Success!
		    var data = JSON.parse(this.response);
	    	resolve(data);

		  } else {
		    // We reached our target server, but it returned an error
		    reject('Sorry, there was an error querying ElasticSearch.')

		  }
		};

		request.onerror = function() {
		  // There was a connection error of some sort
		  reject('Sorry, there was an error connecting to the ElasticSearch instance.')
		};

	});

}

function esQuery(query, callback){

  var endpointUrl = new URL(esUrl);

  const username = endpointUrl.username;
  const password = endpointUrl.password;
  const authHeader = 'Basic ' + btoa(`${username}:${password}`);

  const headers = new Headers({"Content-Type": "application/json"});
  var credentials = false;

  var searchEndpoint = endpointUrl.href;
  if ( username !== "" && password !== "" ) {
      // FIXME: There should be a nicer way to remove the user / pass..    
      searchEndpoint = endpointUrl.href.replace(`${username}:${password}`,'');

      headers.append("Authorization", JSON.stringify(authHeader));
      credentials = true;
  }

  try {
      return fetch(searchEndpoint, {
                  method: "POST", 
                  credentials: credentials ? "include" : "same-origin",
                  mode: 'cors',
                  headers: headers,
                  body: JSON.stringify(query) }).then( response => response.json() );        

  } catch(err) {
      alert(`We did not get a proper response from the endpoint! ${err}`)
  }


}