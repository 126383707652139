/*
searchConfigContext.js

A context singleton for handling endpoint configuration 
*/

import React, { createContext, useState } from 'react';

const SearchConfigContext = createContext({endpoint: '', mapping: '', search: ''})

const SearchConfigProvider = ({value, children}) => {

    const [ endpoint, setEndpoint ] = useState(value.endpoint)
    const [ mapping, setMapping ] = useState(value.mapping)
    const [ searchConfig, setSearch ] = useState(value.searchConfig)

    return ( <SearchConfigContext.Provider value={{endpoint, mapping, searchConfig, filters: value.filters}}>
        {children}
    </SearchConfigContext.Provider> )
}

export { SearchConfigContext, SearchConfigProvider }

