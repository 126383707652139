import React, { useState, useRef } from 'react';
import { FilterControl } from './facetControl.js';

import '../../css/search.scoped.scss';

const FiltersAccordion = (props) => {
    const [ openPane, setOpenPane ] = useState(null);

    const refs = new Array(props.filterPaneConfigs.length);
    props.filterPaneConfigs.forEach(paneConfig => {
        refs[paneConfig.name] = useRef();
    });

    return (
        <div className="accordion" id={props.accordionId}>
        {
          props.filterPaneConfigs.map(paneConfig => {
            return (
                <div className="card" key={`key-filters-accordion-card-${paneConfig.name}`}>
                  <div
                    className="mfp-collapse-hdg"
                    id={`heading-${paneConfig.name}`}
                  >
                    <h2 className="mb-0 pb-2">
                      <button
                        className={`btn btn-link mfp-heading-text ${openPane === paneConfig.name ? "" : "collapsed"}`}
                        type="button"
                        data-target={`#collapse-${paneConfig.name}`}
                        aria-expanded="false"
                        aria-controls={`collapse-${paneConfig.name}`}
                        onClick={() => setOpenPane(prevOpen => (prevOpen === paneConfig.name ? null : paneConfig.name))}
                      >
                        <i className="icon icon-accordion-carets icon--slate-gray pr-1 mr-1"></i>{" "}
                        {paneConfig.label}
                      </button>
                    </h2>
                  </div>
                  <div
                    id={`collapse-${paneConfig.name}`}
                    className={`collapse ${openPane === paneConfig.name ? "is-active" : ""}`}
                    aria-labelledby={`heading-${paneConfig.name}`}
                    data-parent={props.accordionId}
                    ref={refs[paneConfig.name]}
                    style={openPane === paneConfig.name
                        ? { height: refs[paneConfig.name].current.scrollHeight }
                        : { height: "0px" }
                    }
                  >
                    <div className="card-body">
                      {
                        paneConfig.filters.map(filterConfig => {
                          return <FilterControl
                            aggregations={props.aggregations}
                            filterConfig={filterConfig}
                            filters={props.filters}
                            applyFilters={props.applyFilters}
                            clearFilter={props.clearFilter}
                            count={props.count}
                            collapseFunc={props.collapseAllFunc}
                            mobileMode={true}
                            key={`key-filter-control-${filterConfig.name}`}
                          />
                        })
                      }
                    </div>
                  </div>
                </div>
            )
          })
        }
        </div>
    )
}

export { FiltersAccordion };